<template>
  <div>
    <c-table
      ref="table"
      title="관련설비 검사/확인 목록"
      tableId="riskHazrd01"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable&&!param.disabled"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&param.disabled" label="저장" icon="save" @btnClicked="save" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'moc-facility-restoration-emergency',
  components: {
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '설비코드',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'link',
          },
          {
            name: 'col2',
            field: 'col2',
            label: '설비명',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'col3',
            field: 'col3',
            label: '설비유형',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '원복여부',
            align: 'center',
            sortable: true,
            style: 'width:70px',
          },
          {
            name: 'checkerNm',
            field: 'checkerNm',
            label: '검사자',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'user',
            userId: 'checkerId'
          },
          {
            name: 'col6',
            field: 'col6',
            label: '검사결과',
            align: 'left',
            sortable: true,
            style: 'width:400px',
            type: 'textarea',
          },
          {
            name: 'confirmerNm',
            field: 'confirmerNm',
            label: '확인자',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'user',
            userId: 'confirmerId'
          },
          {
            name: 'col7',
            field: 'col7',
            label: '확인결과',
            align: 'left',
            sortable: true,
            style: 'width:400px',
            type: 'textarea',
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.grid.data = [
        { col1: '설비코드1', col2: '설비명1', col3: '설비유형1', col4: 'O', col6: '-', col7: '-', confirmerId: '', confirmerNm: '', checkerId: '', checkerNm: '', },
        { col1: '설비코드2', col2: '설비명2', col3: '설비유형2', col4: 'O', col6: '-', col7: '-', confirmerId: '', confirmerNm: '', checkerId: '', checkerNm: '', },
        { col1: '설비코드3', col2: '설비명3', col3: '설비유형3', col4: 'O', col6: '-', col7: '-', confirmerId: '', confirmerNm: '', checkerId: '', checkerNm: '', },
        { col1: '설비코드4', col2: '설비명4', col3: '설비유형4', col4: 'O', col6: '-', col7: '-', confirmerId: '', confirmerNm: '', checkerId: '', checkerNm: '', }
      ]
    },
    save() {
      // let checkItem = ['plantCd', 'col1', 'col2']
      // let isConti = true;
      // this.$_.forEach(this.grid.data, item => {
      //   this.$_.forEach(checkItem, check => {
      //     if (!item[check]) {
      //       isConti = false;
      //       return false;
      //     }
      //   })
      // });

      // if (!isConti) {
      //   window.getApp.$emit('ALERT', {
      //     title: '안내',
      //     message: '필수 입력값을 입력해 주세요. [사업장, 평가구분, 평가항목]',
      //     type: 'warning', // success / info / warning / error
      //   });
      // }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = row;
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.moc-timeline
  max-height: 550px
  .q-timeline__heading-title
    padding-bottom: 10px
</style>